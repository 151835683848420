import EmblaCarousel from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";
import AutoScroll from "embla-carousel-auto-scroll";

// Configuration object mapping selectors to their respective options and plugins

EmblaCarousel.globalOptions = { 
    container: 'embla__container',
 };

const carouselConfigurations = {
  ".slider-hero": {
    options: { loop: true },
    plugins: [Autoplay()],
  },
  ".slider-gallery-forward": {
    options: { loop: true },
    plugins: [AutoScroll({
        speed: 1,
        stopOnInteraction: false,
    })],
  },
  ".slider-gallery-backward": {
    options: { loop: true, startIndex: 3, },
    plugins: [AutoScroll({
        speed: 1,
        stopOnInteraction: false,
        direction: "backward",
    })],
  },
  // Add more configurations as needed
};

// Iterate over the configuration object
Object.keys(carouselConfigurations).forEach((selector) => {
  // Select all carousel nodes for the current selector
  const carouselNodes = document.querySelectorAll(selector);

  // Check if nodes exist and initialize each carousel
  if (carouselNodes.length > 0) {
    carouselNodes.forEach((node) => {
      const { options, plugins } = carouselConfigurations[selector];
      const viewportNode = node.querySelector(".embla__viewport");
      const emblaApi = EmblaCarousel(viewportNode, options, plugins);
    });
  }
});

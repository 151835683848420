import "./alpine.config.js";
import "./sliders.js";
import "./scripts.js";
import { trackCampaigns } from "./campaigns.js";
import { delayScripts } from "./delay-scripts.js";

delayScripts();
setTimeout(() => {
  trackCampaigns({ debug: false });
}, 3000);

// export const delayScripts = (delay = 5) => {

//     const autoLoadDuration = delay; //In Seconds
//     console.log(`delay: ${delay}`);
//     console.log(`autoLoadDuration: ${autoLoadDuration}`);
//     const eventList = ["keydown", "mousemove", "wheel", "touchmove", "touchstart", "touchend"];
    
//     const autoLoadTimeout = setTimeout(runScripts, autoLoadDuration * 1000);
    
//     eventList.forEach(function(event) {
//         window.addEventListener(event, triggerScripts, { passive: true })
//     });
    
//     function triggerScripts() {
//         runScripts();
//         clearTimeout(autoLoadTimeout);
//         eventList.forEach(function(event) {
//             window.removeEventListener(event, triggerScripts, { passive: true });
//         });
//     }
    
//     function runScripts() {
//         document.querySelectorAll("script[delay]").forEach(function(scriptTag) {
//             console.dir(scriptTag.getAttribute("delay"));
//             scriptTag.setAttribute("src", scriptTag.getAttribute("delay"));
//         });
//     }
// }

// export const delayScripts = (delay = 5) => {
//     const autoLoadDuration = delay * 1000; // Convert to milliseconds
//     const eventList = ["keydown", "mousemove", "wheel", "touchmove", "touchstart", "touchend"];
    
//     // Ensure runScripts is defined before using it
//     const runScripts = () => {
//         document.querySelectorAll("script[delay]").forEach(scriptTag => {
//             console.dir(scriptTag.getAttribute("delay"));
//             scriptTag.setAttribute("src", scriptTag.getAttribute("delay"));
//         });
//     };

//     // Initialize autoLoadTimeout after runScripts is defined
//     let autoLoadTimeout = setTimeout(runScripts, autoLoadDuration);
    
//     const triggerScripts = () => {
//         clearTimeout(autoLoadTimeout); // Clear the timeout to prevent runScripts from being called twice
//         runScripts();
//         eventList.forEach(event => window.removeEventListener(event, triggerScripts, { passive: true }));
//     };
    
//     eventList.forEach(event => window.addEventListener(event, triggerScripts, { passive: true }));
// };

export const delayScripts = (delay = 5, tags = ["script", "iframe"]) => {
    const autoLoadDuration = delay * 1000; // Convert to milliseconds
    const eventList = ["keydown", "mousemove", "wheel", "touchmove", "touchstart", "touchend"];
   
    const runScripts = () => {
        tags.forEach(tag => {
            document.querySelectorAll(`${tag}[delay]`).forEach(element => {
                const dataSrc = element.getAttribute("data-src");
                // console.dir(dataSrc);
                element.setAttribute("src", dataSrc);
            });
        });
    };

    let autoLoadTimeout = setTimeout(runScripts, autoLoadDuration);
    
    const triggerScripts = () => {
        clearTimeout(autoLoadTimeout); // Clear the timeout to prevent runScripts from being called twice
        runScripts();
        eventList.forEach(event => window.removeEventListener(event, triggerScripts, { passive: true }));
    };
    
    eventList.forEach(event => window.addEventListener(event, triggerScripts, { passive: true }));
};
